(function() {
    var setCookie = function(value) {
        var expires;

        if (d3r_tracking_expires == 0) {
            expires = '0';
        } else {
            var date = new Date();
            date.setTime(d3r_tracking_expires * 1000);

            expires = date.toUTCString();
        }

        var parts = [
            "d3rt=" + (encodeURIComponent(value) || ""),
            "expires=" + expires,
            "path=/",
        ];

        if (window.location.protocol === 'https:') {
            parts.push('secure');
        }

        document.cookie = parts.join('; ');
    }

    var getCookie = function() {
        var nameEQ = "d3rt=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return decodeURIComponent(c.substring(nameEQ.length,c.length));
        }
        return null;
    }

    var getTrack = function() {
        var referrer = getReferrer();
        return encodeURIComponent(window.location.pathname + window.location.search)
            + "|"
            + encodeURIComponent(referrer ? referrer : 'Direct')
            + "|"
            + Math.floor(new Date().getTime()/1000);
    }

    var getReferrer = function () {
        if (document.referrer
            && document.referrer.indexOf(window.location.hostname) === -1
            && window.location.search.indexOf('utm_nooverride=1') === -1
        ) {
            return document.referrer.substr(0,64);
        }
        return false;
    }

    var decode = function(str) {
        var obj = {};
        var parts = str.split("&");
        parts.forEach(function(piece) {
            obj[piece[0]] = piece.substring(2);
        })
        return obj;
    }

    var encode = function(obj) {
        var encoded = [];
        for (var prop in obj) {
            encoded.push(prop + "/" + obj[prop]);
        }
        return encoded.join("&");
    }

    var data;
    try {
       data = decode(getCookie("d3rt")) || {};
    } catch (error) {
        data = {};
    }

    var track = getTrack(d3r_tracking_key);

    var refer = true;
    if (!data.f) {
        data.f = track;
        refer = false;
    }
    if (match = window.location.search.match(new RegExp('(?:\\?|&)'+d3r_tracking_key+'=[^&]+'))) {
        refer = false;
        data.c = track;
    }
    if (refer && getReferrer()) {
        data.r = track;
    }

    setCookie(encode(data));
})();
